import { render, staticRenderFns } from "./RegionManage.vue?vue&type=template&id=93dbec08&scoped=true&"
import script from "./RegionManage.vue?vue&type=script&lang=js&"
export * from "./RegionManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93dbec08",
  null
  
)

export default component.exports